<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <Navigation />
    <!-- 更换背景 -->
    <div class="bg_box">
      <div class="noneBox"></div>
      <div class="box">
        <p class="back" @click="$router.push('/aiNav')">返回</p>
        <div class="ct_box flex">
          <div class="form_upload_box">
            <ai_title ai_title="“AI助手”-抠图" />
            <ai_upload_img @getImg="getImg" />
            <ai_button_file_data @file_data="file_data" />
            <ai_img :type="2" contentName='抠图后的图片' :contentData="contentData" />
            <aiNum title="计费规则：本次抠图预计消费0.6元" :visible="aiNumShow" @close="aiNumShow = false" @postData="postData" />
            <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
          </div>
          <aiNav />
        </div>
      </div>
    </div>
    <footerBot />
  </div>
</template>

<script>
import ai_upload_img from '@/components/ai_upload_img'
import ai_img from '@/components/ai_img'
import aiNav from '@/components/aiNav'
import ai_title from '@/components/ai_title'
import ai_button_file_data from '@/components/ai_button_file_data'
export default {
  mounted() {
    this.getUserInfo()
  },
  components: {
    aiNav,
    ai_upload_img,
    ai_img,
    ai_title,
    ai_button_file_data
  },
  data() {
    return {
      fileList: [],
      contentData: {
        origin_url: '',

        // cover_url:''
        output_content: '',
      }, //内容
      fullscreenLoading: false,
      aiNumShow: false,
      aiPoupShow: false,
    }
  },
  methods: {
    getImg(url) {
      this.contentData.origin_url = url
      console.log('contentData', this.contentData);
    },
    // 获取用户信息
    getUserInfo() {
      let $user_info = JSON.parse(localStorage.getItem('user_info'))
      if ($user_info) {
        this.curlGet('/api/user/info').then((res) => {
          if (res.data.code) {
            this.$user_info = res.data.data
            this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
          }
        })
      }
    },
    file_data() {
      if (this.$user_info.money <= 0) {
        this.aiPoupShow = true
      } else {
        this.aiNumShow = true
      }
    },
    postData() {
      this.aiNumShow = false
      this.fullscreenLoading = true
      let data = {}
      data.mobile = this.$user_info.mobile
      for (const key in this.contentData) {
        if (key != 'output_content') {
          data[key] = this.contentData[key]
        }
      }
      this.curlPost('file/drawing_matting', data).then(res => {
        console.log(res);
        if (res.data.code) {
          this.contentData.output_content = res.data.data.output_content

          // 扣费
          this.curlPost('/api/users/money_log/spend', {
            ai_id: res.data.data.id,
            amount: res.data.data.money,
          }).then(res => {
            if (res.data.code) {
              this.getUserInfo()
            }
          })
        } else {
          this.$message.error(res.data.msg);
        }
        this.fullscreenLoading = false
      })
    }

  },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>